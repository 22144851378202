import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  client: 'relevant',
  fonts: {
    primary: 'Produkt',
    secondary: 'Gotham',
    tertiary: 'Gotham Office',
    sfPro: 'SF Pro', // from the second theme
    weight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semiBold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    size: {
      xxs: '0.09375rem', // 1.5px
      xs: '0.7rem', // 11.2px from first theme
      xs1: '0.75rem', // 12px from first theme
      sm: '0.8125rem', // 13px from first theme
      sm1: '0.875rem', // 14px from first theme
      sm2: '0.938rem', // 15.008px
      sm3: '0.95rem', // 15.2px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      xl2: '1.4rem', // 22.4px
      xl3: '1.5rem', // 24px
      xl4: '1.875rem', // 30px
      xl5: '2.25rem', // 36px
      xl6: '2.5rem', // 40px
      xl7: '3rem', // 48px
      xl8: '3.125rem', // 50px
      xl9: '3.175rem', // 50.8px
      xl10: '4rem', // 64px
      xl11: '4.2rem', // 67.2px
    },
    family: {
      primary: 'Produkt',
      secondary: 'Gotham',
      tertiary: 'Gotham Office',
      sfPro: 'SF Pro', // retained from second theme
    },
  },
  colors: {
    black: {
      200: '#1F1F25',
      300: '#333333',
      400: '#222222',
      500: '#121212',
      600: '#383840',
      900: '#000',
    },
    grey: {
      0: '#FFFFFF',
      100: '#F9F9F9',
      200: '#F2F2F2',
      300: '#dedede',
      400: '#A6A6A6',
      500: '#666666',
      600: '#333333',
      700: '#222222',
      800: '#000000',
      900: '#000000',
      1000: '#000000', // from second theme
    },
    icons: {
      blue: '#21b4ef',
      green: '#67c272',
      purple: '#623a9d',
      red: '#c21f30',
      yellow: '#E4AD3F',
    },
    vividTangerine: '#ff7f7f',
    lightCoral: '#F08585',
    darkGray: '#777777',
    darkGrayBlue: '#A3A5A9',
    primary: {
      100: '#E2F5FD', // from second theme
      200: '#C4EBFB',
      450: '#14BCFF',
      500: '#21B4EF',
      600: '#18A2DA',
      lighter: '#c4ebfb',
      light: '#14bcff',
      main: '#21B4EF',
      dark: '#18a2da',
    },
    secondary: {
      light: '#39c7ff',
      main: '#21B4EF',
      dark: '#18a2da',
    },
    green: {
      200: '#CAEFCF',
      450: '#69D776',
      500: '#67C272',
      600: '#5DB267',
    },
    red: {
      200: '#FFE2E6',
      450: '#DF2A3D',
      500: '#C21F30',
      600: '#AE1E2D',
    },
    mustard: {
      200: '#FFF2D8',
      450: '#F7BD48',
      500: '#E4AD3F',
      600: '#D29D34',
    },
    purple: {
      200: '#E6DBF7',
      450: '#6F41B2',
      500: '#623A9D',
      600: '#55308B',
    },
    stateColors: {
      danger: {
        lighter: '#ffe2e6',
        light: '#f3445f',
        main: '#e33751',
        dark: '#c83047',
        darker: '#c21f30',
        darkest: '#C21F30',
      },
      success: {
        lighter: '#caefcf',
        light: '#70d47c',
        main: '#67c272',
        dark: '#5dae67',
      },
      info: {
        lighter: '#c4ebfb',
        light: '#7ED3F6',
        main: '#14bcff',
        dark: '#21b4ef',
      },
      review: '#623A9D',
      lightDeactivated: '#F0F1F0',
      deactivated: '#979797',
    },
  },
  spaces: {
    p4: '4px',
    p8: '8px',
    p12: '12px',
    p16: '16px',
    p20: '20px',
    p24: '24px',
    p32: '32px',
    p48: '48px',
    p64: '64px',
  },
  spacing: {
    1: '0.25rem',
    1.25: '0.3125rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    3.75: '0.09375rem',
    4: '1rem',
    4.5: '1.125rem', // from second theme
    5: '1.25rem',
    5.5: '1.375rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.75rem',
    12: '3rem',
    14: '3.5rem',
    15: '3.75rem',
    16: '4rem',
    17: '4.25rem', // from second theme
    20: '5rem',
    23: '5.625rem', // from second theme
    24: '6rem',
    28: '7rem',
    28.75: '7.75rem', // from second theme
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '960px',
    lg: '1280px',
    adminLg: '1450px',
    xl: '1920px',
  },
  styles: {
    borderRadius: '5px', // from second theme
    elevation: {
      press: '0px 2px 3px 0px rgba(0, 0, 0, 0.15)',
      elevation1: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
      elevation2: '0px 8px 16px 0px rgba(0, 0, 0, 0.15)',
      elevation3: '0px 16px 24px 0px rgba(0, 0, 0, 0.15)',
      elevation4: '0px 24px 32px 0px rgba(0, 0, 0, 0.15)',
    },
  },
};

export const solycoTheme: DefaultTheme = {
  ...theme,
  client: 'solyco',
  fonts: {
    ...theme.fonts,
    primary: 'Lora',
    family: {
      ...theme.fonts.family,
      primary: 'Lora',
    },
  },
  colors: {
    ...theme.colors,
    primary: {
      ...theme.colors.primary,
      100: '#9CAE87',
      200: '#9CAE87',
      450: '#9CAE87',
      500: '#9CAE87',
      600: '#9CAE87',
      lighter: '#9CAE87',
      light: '#9CAE87',
      main: '#9CAE87',
      dark: '#9CAE87',
    },
  },
  styles: {
    ...theme.styles,
    borderRadius: '0px',
  },
};

export default theme;
